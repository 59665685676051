$background-color: #17191d;
$foreground-color: #d0d2e3;
$primary-color: #125cec;
$primary-hover-color: #5e94ff;
$secondary-color: #363b45;
$secondary-hover-color: #4f5664;

i.link.icon.disabled {
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.45 !important;
  }
}

i.link.icon.disabled {
  cursor: not-allowed !important;
  &:hover {
    opacity: 0.45 !important;
  }
}

.dark {
  background-color: $background-color;
  color: $foreground-color;

  .ui.selection.dropdown .menu > .message {
    color: white;
  }

  .ui.dropdown {
    &:focus-within {
      border: 1px solid #96c8da;
    }
  }
  .ui.input {
    input,
    input:focus,
    input[type="text"] {
      background: $background-color;
      color: $foreground-color;
      border: 0px;
    }

    &:focus-within {
      border: 1px solid #96c8da;
    }

    .ui.basic.button.dropdown {
      border-left: 2px solid #a7a9b7;
    }

    .ui.selection.dropdown {
      border-width: 0px;
    }

    color: $foreground-color;
    border: 1px solid #a7a9b7;
    border-radius: 2px;
  }

  textarea {
    background-color: $background-color;
    color: $foreground-color;
    border: 1px solid #a7a9b7;
    border-radius: 2px;

    &:hover {
      border-color: #96c8da;
    }

    &:focus {
      border: 2px solid #96c8da;
      outline: none;
    }
  }

  input::selection {
    color: rgba(255, 255, 255, 0.87);
  }

  .ui.labeled.input {
    .ui.label {
      background: #2a2b33;
      color: unset;
      border-radius: 5px;
    }
  }

  .ui.pagination.menu .active.item {
    color: rgba(255, 255, 255, 0.95);
  }

  .ui.labels .ui.label {
    background-color: #1fba8b;
    color: white;
  }

  .rc-slider-rail {
    background-color: gray;
  }

  .ui.checkbox {
    .box:before,
    label:before,
    input:checked ~ .box:before,
    input:checked:focus ~ label:before,
    input:checked ~ .box:after,
    input:checked ~ label:after {
      background-color: $background-color !important;
      border: 1px solid $foreground-color !important;
      color: $foreground-color !important;
      border-radius: 0.21428571rem;
    }

    .box:hover:before,
    label:hover:before {
      background-color: $background-color;
      border: 1px solid $foreground-color;
      border: 1px solid #96c8da !important;
      border-radius: 0.21428571rem;
    }
  }

  .ui.checkbox.radio.checkbox label:before {
    background-color: white !important;
  }

  .ui.checkbox label,
  .ui.checkbox + label {
    color: rgba(255, 255, 255, 0.87);
  }

  .ui.toggle.checkbox {
    .box:before,
    label:before,
    input:checked ~ .box:before,
    input:checked ~ label:before,
    input:checked ~ box:before {
      // background-color: $background-color !important;
      border: 1px solid $foreground-color !important;
      color: $foreground-color !important;
      border-radius: 500rem;
    }

    input:focus:checked ~ label:before {
      background-color: #2185d0 !important;
    }

    input:checked ~ .box:after,
    input:checked ~ label:after {
      background-color: rgb(255, 255, 255) !important;
      border-radius: 500rem;
    }

    .box:hover:before,
    label:hover:before {
      background-color: $background-color;
      border: 1px solid $foreground-color;
      border: 1px solid #96c8da !important;
      border-radius: 500rem;
    }
  }

  .ui.button.primary {
    background-color: $primary-color;
    color: #d6d6d6;

    &:hover {
      background-color: $primary-hover-color;
      color: #fff;
    }
  }

  .ui.button.secondary {
    background-color: $secondary-color;
    color: #d6d6d6;

    &:hover {
      background-color: $secondary-hover-color;
      color: #fff;
    }
  }

  .ui.button.add-panel-button {
    background-color: $secondary-color;
    color: #d6d6d6;

    &:hover {
      background-color: $primary-color;
      color: #fff;
    }
  }

  .ui.table,
  .ui.sortable.table {
    background: unset;
    color: #d0d2e3;
    border-color: rgba(200, 200, 200, 0.55);

    thead th,
    thead th.sorted {
      background: $secondary-color;
      color: #d6d6d6;
    }

    thead th:hover,
    thead th.sorted:hover {
      background: $secondary-hover-color;
      color: #d6d6d6;
    }

    tbody tr td {
      border-bottom: 1px solid #242529;
    }

    tbody tr:last-child td {
      border-bottom: unset;
    }
  }

  .ui.celled.table tr th, .ui.celled.table tr td {
    border-left: 1px solid rgba(200, 200, 200, 0.3);
    border-top: 1px solid rgba(200, 200, 200, 0.3);
  }
  
  .ui.table th,
  .ui.table td {
    overflow: visible !important;
  }

  .ui.selectable.table tbody tr:hover,
  .ui.table tbody tr td.selectable:hover {
    background: rgba(255, 255, 255, 0.05) !important;
    color: rgba(255, 255, 255, 0.95) !important;
  }

  .ui.inverted.table tr.warning,
  .ui.inverted.table td.warning {
    background: rgb(84, 84, 84) !important;
    color: rgb(255, 255, 255) !important;
  }

  .ui.inverted.table tr.error,
  .ui.inverted.table td.error {
    background: rgb(84, 84, 84) !important;
    color: rgb(255, 255, 255) !important;
  }

  .ui.selection.dropdown {
    background-color: unset !important;
    color: #fff;
    border-color: #a7a9b7;

    &:hover {
      border-color: #96c8da;
    }

    &,
    &.visible {
      .text:not(.default) {
        color: rgba(255, 255, 255, 0.85);
      }
    }
  }

  .ui.multiple.search.dropdown > input.search {
    color: rgba(255, 255, 255, 0.85);
  }

  .mail-tags {
    background: unset;
    color: unset;
  }

  .ui.segment {
    background: unset;
    color: unset;
  }

  .action-buttons {
    width: fit-content;
    display: flex;
    justify-content: flex-start;
  }

  .ui.buttons .or:before {
    background-color: $background-color;
    color: unset;
  }

  .ui.basic.button {
    background: unset !important;
    color: unset !important;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  .ui.form {
    input,
    input[type="file"] {
      background: $background-color;
      color: unset;

      &:focus {
        background: unset !important;
        color: unset !important;
      }
    }

    .field > label {
      color: $foreground-color;
    }
    textarea {
      background: $background-color;
      border: #46474b 1px solid;
      color: unset;

      &:focus {
        background: unset;
        color: unset;
      }
    }
  }

  .ui.breadcrumb .divider {
    color: #4183c4;
  }

  .ui.accordion .title:not(.ui) {
    color: unset;
  }

  .ui.black.message {
    background-color: #2a2b33;
  }

  .ui.divider {
    background-color: rgba(255, 255, 255, 0.25);
  }

  .ui.selection.active.dropdown .menu {
    border-color: #96c8da;
  }

  .ui.dropdown .menu {
    background-color: #2f2f34 !important;
    box-shadow: rgba(0, 0, 0, 0.5) 4px 4px 8px !important;
    margin-top: 2px !important;
    padding: 8px 0px;
    color: rgba(255, 255, 255, 0.85) !important;
    border: 1px solid rgba(82, 82, 115, 0.5) !important;
    border-top: none !important;

    .item {
      color: rgba(255, 255, 255, 0.85) !important;
      border-top: 0px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1) !important;
      }
    }

    .divider {
      border-top: 1px solid rgba(221, 219, 217, 0.5);
    }
  }

  .ui.menu .ui.dropdown .menu {
    .item {
      color: rgba(255, 255, 255, 0.85) !important;
      border-top: 0px;

      &:hover {
        color: #fff !important;
        background-color: rgba(255, 255, 255, 0.1) !important;
      }
    }

    .active.item {
      color: $foreground-color !important;
      background-color: $secondary-color !important;

      &:hover {
        color: #fff !important;
        background-color: $secondary-hover-color !important;
      }
    }

    .divider {
      border-top: 1px solid rgba(255 - 34, 255 - 36, 255 - 38, 0.5);
    }
  }

  .ui.selection.dropdown .menu > .item {
    border-top: 0px;
  }

  .ui.dropdown .menu {
    background-color: #2a2b33;
    border: 0px;

    .selected.item {
      background-color: rgba(255, 255, 255, 0.03);
    }

    .item:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }

    .item {
      color: unset;
    }
  }

  .last-refresh-time {
    position: fixed;
    bottom: 0px;
    left: 0px;
    min-width: 190px;
    background-color: #141518;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .last-refresh-time-success {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: #2d5e2e;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .last-refresh-time-error {
    position: fixed;
    bottom: 0px;
    left: 0px;
    min-width: 190px;
    background-color: #da2828;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .last-refresh-time-stale {
    position: fixed;
    bottom: 0px;
    left: 0px;
    min-width: 190px;
    background-color: #801818;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  .info-icon {
    position: fixed;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0);
    margin-bottom: 10px;
    margin-left: 10px;
    border-radius: 5px;
  }

  .ui.search.dropdown.active > input.search,
  .ui.search.dropdown.visible > input.search {
    color: $foreground-color;
  }
}

.ui.dropdown .angle.double.right.icon {
  margin: 0px;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.ui.message {
  background-color: #2a2b33 !important;
  color: #e0e0e1 !important;
  border: 1px solid #3d3e42 !important;
  border-radius: 5px !important;
}

.beta-label {
  height: 55px !important;
  width: 55px !important;
}
