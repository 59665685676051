.action .ui.accordion .title:not(.ui) {
  padding: 0px;
}

.action {
  margin-bottom: 20px;
}

.hover-cell:hover {
  background: rgba(255, 255, 255, 0.05) !important;
  color: rgba(255, 255, 255, 0.95) !important;
}
.ml-10 {
  margin-left: 10px;
}
.w-full {
  width: 100%;
}
.pd-cell {
  padding: 0.78571429em 0.78571429em;
}

.ui.fullscreen.modal {
  height: unset !important;
}