#content {
  width: 100%;
}

.layoutJSON {
  background: #333333;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}
.columns {
  -moz-columns: 120px;
  -webkit-columns: 120px;
  columns: 120px;
}
.react-grid-item {
  box-sizing: border-box;
  border: none;
}

.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  background: #cce;
}
.react-grid-item .minMax {
  font-size: 12px;
}
.react-grid-item .add {
  cursor: pointer;
}
.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.react-grid-item.react-grid-placeholder {
  background-color: rgb(11, 129, 240);
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}
.toolbox__items {
  display: block;
}
.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}
.droppable-element {
  width: 150px;
  background: #ddd;
  border: 1px solid black;
  margin-top: 10px;
  padding: 10px;
}

.dashboard-links {
  margin-top: -10px;
}

.dashboard-links > .ui.button {
  margin-top: 10px;
}

.bytebeam-panel-header {
  width: 100%;
  z-index: 10;
  vertical-align: middle;
  font-weight: bold;
  font-size: 13px;
  position: absolute;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.bytebeam-panel-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 15px;
  padding-right: 15px;
  position: absolute;
  top: 0px;
  right: 0px;
}

.bytebeam-panel-icon {
  margin-right: 5px;
  margin-left: 5px;
}

.bytebeam-panel-icon .ui.inline.loader.active {
  margin-top: -3px;
}

.bytebeam-panel-icons i.link.icon {
  margin-right: 0px;
}

.bytebeam-panel-content {
  width: 100%;
  height: 100%;
  position: absolute;
}

.bytebeam-panel .infolayer {
  transform: translate(0, 10px);
}

.dashboard-header-icon {
  padding-left: 5px;
  font-size: large;
}

.dashboard-header-icon-save {
  display: inline-block;
  padding-left: 5px;
  height: 36px;
}

.dashboard-header-timestamp {
  font-size: 14px;
}

.dashboard-header-dropdown {
  display: inline-block;
  margin-right: 10px;
}

.dashboard-header-dropdown .ui.multiple.dropdown {
  min-width: 175px;
  max-width: 500px !important;
  flex-wrap: wrap !important;
  padding-right: 25px !important;
}

.dashboard-header-dropdown .ui.multiple.dropdown > .ui.label {
  display: flex;
}

.dashboard-header-dropdown .ui.multiple.dropdown .menu {
  width: fit-content;
}

.dashboard-header-dropdown .ui.selection.dropdown {
  min-height: 1em;
}

@media (max-width: 480px) {
  .dashboard-header-dropdown {
    min-width: 100% !important;
  }
  .dashboard-header-dropdown .ui.multiple.dropdown {
    min-width: 100% !important;
  }
}

.date-time-picker {
  padding-left: 10px;
  padding-right: 10px;
}

.date-time-input {
  display: flex;
  flex-direction: row;
}

.date-time-inputbox {
  max-width: 62%;
}

.date-time-inputbox.ui.input > input {
  border-radius: 0px;
  border-right: 0px;
}

.date-time-input .ui.selection.dropdown {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: unset;
}

.filter-dropdown {
  max-width: 200px;
}

.filter-dropdown .ui.multiple.dropdown > .label {
  vertical-align: unset;
  margin: 3px !important;
}

.filter-dropdown .ui.labeled.icon.button {
  padding-left: 2.8em !important;
}

.date-time-picker .ui.table tr td {
  padding: 5px;
}

.date-time-picker .ui.table tr th {
  padding: 5px;
}

div.plotly-notifier {
  visibility: hidden;
}

.add-panel-modal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.add-panel-graph-placeholder {
  height: 300px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
}

.add-panel-title-input {
  align-self: flex-start;
  width: 100%;
  border-radius: 5px !important;
}

.fixed-label-width-big {
  min-width: 120px;
}

.fixed-label-width-small {
  min-width: 60px;
}

.add-panel-divider {
  width: 100%;
}

.bytebeam-panel {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
}

.add-panel-modal .ui.input {
  border-radius: 6px !important;
  border-width: 1px !important;
}

.fullscreen {
  height: 100%;
  width: 100%;
}

.add-panel-plot {
  width: 500px;
  height: 300px;
}

.panel-no-data {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list-dashboards-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui.labeled.icon.button > .icon.dashboard-refresh-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px !important;
}

.rc-slider {
  margin: 8px;
}

.cs-editor {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 1002;
}
.cs-editor-icon {
  z-index: 1003;
  float: right;
}

.gm-style-mtc > button {
  background-color: #13324d !important;
  color: #a9a9a9 !important;
}

.ui.attached.segment {
  border: 1px solid #d4d4d5 !important;
}

.toggle-switch {
  position: relative;
  width: 50px;
  display: inline-block;
  vertical-align: middle;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 13px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner:before,
.toggle-switch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 23px;
  padding: 0;
  line-height: 23px;
  font-size: 9px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: attr(data-yes);
  text-transform: uppercase;
  padding-left: 7px;
  background-color: #7ca2ef;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-disabled:before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-inner:after {
  content: attr(data-no);
  text-transform: uppercase;
  padding-right: 7px;
  background-color: rgb(61, 61, 61);
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 16px;
  margin: 3px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 27px;
  border: 0 solid #ccc;
  border-radius: 13px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
.toggle-switch.small-switch {
  width: 40px;
}
.toggle-switch.small-switch .toggle-switch-inner:after,
.toggle-switch.small-switch .toggle-switch-inner:before {
  content: "";
  height: 13px;
  line-height: 13px;
}
.toggle-switch.small-switch .toggle-switch-switch {
  width: 11px;
  right: 13px;
  margin: 1px;
}

/* animate input labels on focus CSS start  */
.triggerLabel {
  opacity: 1 !important;
  line-height: 20px !important;
  font-size: 12px !important;
  top: -19px !important;
  background: #17191d !important;
  padding: 0 6px !important;
  left: 9px !important;
  z-index: 1 !important;
}
/* animate input labels on focus CSS end  */

.add-panel-title-input input:not(:placeholder-shown) + div {
  opacity: 1 !important;
  line-height: 20px !important;
  font-size: 12px !important;
  top: -12px !important;
  padding: 0 6px !important;
  left: 9px !important;
  z-index: 1 !important;
}

@media screen and (max-width: 991px) {
  .toggle-switch {
    transform: scale(0.9);
  }
}
@media screen and (max-width: 767px) {
  .toggle-switch {
    transform: scale(0.825);
  }
}
@media screen and (max-width: 575px) {
  .toggle-switch {
    transform: scale(0.75);
  }
}

.errorBorder {
  border: 1px solid #ff0000 !important;
}

.error {
  color: #ff0000 !important;
}

svg.main-svg,
svg.main-svg * {
  overflow: visible !important;
}

.ui.table.stickyTable thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}

.ui.input.error > input {
  background-color: unset !important;
}

.ui.input.error > input::placeholder {
  color: #f95d5a !important;
}

.ui.dropdown.error > .default.text {
  color: #f95d5a !important;
}

.panel-border {
  border: 1px solid #fc3430 !important;
}

.panel-loader-icon {
  position: fixed;
  bottom: 8px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
}

.panel-error-icon {
  position: fixed;
  bottom: 4px;
  right: 24px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5px;
}

.attention-grabbing-button .icon {
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

::-webkit-scrollbar-corner {
  background: rgba(67, 72, 77, 0.2);
}
